export default [
  {
    text: 'Dashboard',
    icon: 'mdi-view-dashboard',
    link: '/',
  },
  {
    text: 'Relatórios',
    icon: 'mdi-clipboard-text-multiple-outline',
    link: '/reports',
  },
  {
    text: 'Tarefas',
    icon: 'mdi-checkbox-marked-circle-auto-outline',
    link: '/customer-tasks',
  },
  {
    text: 'Relatório Complementar',
    icon: 'mdi-chart-box-plus-outline',
    link: '/complementary-report',
  },
  {
    text: 'Clientes',
    icon: 'mdi-account-tie',
    link: '/customers',
  },
  {
    text: 'Usuários',
    icon: 'mdi-account',
    link: '/users',
  },
  {
    text: 'Checklist',
    icon: 'mdi-format-list-checks',
    link: '/checklist-items',
  },
  {
    text: 'Ramos de Atividade',
    icon: 'mdi-family-tree',
    link: '/activity-branches',
  },
  {
    text: 'Setores',
    icon: 'mdi-vector-triangle',
    link: '/sectors',
  },
  {
    text: 'Configurações',
    icon: 'mdi-cog',
    link: '/configurations',
  },
]
